import React, { memo } from 'react';
import { navigate } from 'gatsby';
import Button from '../components/shared/Button';

const ForbiddenPage = () => (
  <div className="md:w-1/2 container px-8 md:px-0 py-16 grid gap-12">
    <div>
      <h1 className="text-5xl font-bold">403 Forbidden</h1>
      <h2 className="mt-1 text-3xl text-primary-500">
        Sorry, you do not have the permission to access this page.
      </h2>
      <div className="mt-12">
        <Button
          onClick={() => {
            navigate('/');
          }}
        >
          Go Back Home
        </Button>
      </div>
    </div>
  </div>
);

export default memo(ForbiddenPage);
